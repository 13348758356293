import React, { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter,
  useNavigate,
  Link,
  Route,
  Routes,
  Switch,
} from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from "sweetalert2";
import Nav from "../Components/Nav";

function Swapcurrencies() {
  const [{ userdetails, loggedin, tradingpair }, dispatch] =
    useContext(GlobalContext);
  const [loading, setloading] = useState(true);

  /// use profits to display earnings too and also
  const [profits, setprofits] = useState(0);

  const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setOpen(!open);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
          setloading(false);
        } else {
          setloggedin(false);
          setOpen(!open);
          navigate("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data());
          setloggedin(true);
          setOpen(!open);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const [amount, setAmount] = useState(0);
  const [fromCurrency, setFromCurrency] = useState("ethbalance");
  const [toCurrency, setToCurrency] = useState("btcbalance");

  const handleSwap = async () => {
    const fromBalance = userdetails[fromCurrency] || 0;
    const toBalance = userdetails[toCurrency] || 0;

    if (!fromCurrency || !toCurrency || fromCurrency === toCurrency) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid currencies selected!",
      });
      return;
    }
  
    if (amount <= 0 || amount > fromBalance) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid amount or insufficient balance!',
      });
      return;
    }
  
    setloading(true);
    const userid = firebase.auth().currentUser;
    const userids = userid.uid;
    const db = firebase.firestore();
    const userRef = db.collection("users").doc(userids);
  
    try {
      await userRef.update({
        [fromCurrency]: parseFloat(fromBalance) - amount,
        [toCurrency]: parseFloat(toBalance) + amount,
      });
      Swal.fire(
        'Successful Transaction!',
        'Swap successful.',
        'success'
      ).then(() => {
        window.location.reload(); // Refresh the page after the success message
      });
    } catch (error) {
      console.error("Error updating document: ", error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error swapping balances!',
      });
    } finally {
      setloading(false);
      setAmount(0);
    }
  };
  
  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await navigate("/");
  };

  return (
    <div>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="stylesheet" href="/stylesheets/output.css" />
      <link rel="stylesheet" href="/stylesheets/dashboard.css" />
      <title>Qfsnesere | Dashboard</title>
      {/* SMARTSUPP */}
      {/* Smartsupp Live Chat script */}
      <noscript>
        {" "}
        Powered by &lt;a href=“https://www.smartsupp.com”
        target=“_blank”&gt;Smartsupp&lt;/a&gt;
      </noscript>
      <div className="min-h-screen relative bg-white">
        {/*DASHBOARD NAVIGATION*/}
        <Nav />
        {/* Flash Messages */}
        <div className="flex flex-col justify-center items-center my-10">
          <section className="flex flex-col items-center w-full md:w-4/6 my-4">
            <div className="w-min rounded-full">
              <div className="flex items-center justify-center bg-rose-50 w-12 h-12 rounded-full m-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth=".75"
                  stroke="currentColor"
                  className="w-16 h-16 text-black"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-bold font-jarkata text-gray-900">
                {userdetails.fullanme}
              </h3>
              <p className="text-gray-700 font-semibold text-base">
                {userdetails.email}
              </p>
            </div>
          </section>
          <section className="flex flex-col w-full md:w-4/6 my-4 ">
            <div
              style={{
                width: "300px",
                margin: "0 auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                Swap Currencies
              </h2>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: "15px" }}>
                  <label
                    style={{
                      display: "block",
                      fontWeight: "bold",
                      marginBottom: "5px",
                    }}
                  >
                    From:
                  </label>
                  <select
                    value={fromCurrency}
                    onChange={(e) => setFromCurrency(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "8px",
                      boxSizing: "border-box",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    <option value="ethbalance">ETH</option>
                    <option value="btcbalance">BTC</option>
                    <option value="xrpbalance">XRP</option>
                    <option value="xlmbalance">XLM</option>
                    <option value="xdcbalance">XDC</option>
                    <option value="dogebalance">DOGE</option>
                    <option value="usdcbalance">USDC</option>
                    <option value="sagebalance">SAGE</option>

                    <option value="solanabalance">SOLANA</option>
                    <option value="usdtethbalance">USDT ETH</option>
                    <option value="bnbbalance">BNB</option>
                    <option value="lcxbalance">LCX</option>
                    <option value="runebalance">RUNE</option>



                  </select>
                  <span
                    style={{
                      display: "block",
                      marginTop: "5px",
                      color: "#555",
                    }}
                  >
                    Balance: {userdetails[fromCurrency]}
                  </span>
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <label
                    style={{
                      display: "block",
                      fontWeight: "bold",
                      marginBottom: "5px",
                    }}
                  >
                    To:
                  </label>
                  <select
                    value={toCurrency}
                    onChange={(e) => setToCurrency(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "8px",
                      boxSizing: "border-box",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    <option value="ethbalance">ETH</option>
                    <option value="btcbalance">BTC</option>
                    <option value="xrpbalance">XRP</option>
                    <option value="xlmbalance">XLM</option>
                    <option value="xdcbalance">XDC</option>
                    <option value="dogebalance">DOGE</option>
                    <option value="usdcbalance">USDC</option>
                    <option value="sagebalance">SAGE</option>

                    <option value="solanabalance">SOLANA</option>
                    <option value="usdtethbalance">USDT ETH</option>
                    <option value="bnbbalance">BNB</option>
                    <option value="lcxbalance">LCX</option>
                    <option value="runebalance">RUNE</option>
                  </select>
                  <span
                    style={{
                      display: "block",
                      marginTop: "5px",
                      color: "#555",
                    }}
                  >
                    Balance: {userdetails[toCurrency]}
                  </span>
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <label
                    style={{
                      display: "block",
                      fontWeight: "bold",
                      marginBottom: "5px",
                    }}
                  >
                    Amount:
                  </label>
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(parseFloat(e.target.value))}
                    style={{
                      width: "100%",
                      padding: "8px",
                      boxSizing: "border-box",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <button
                  onClick={handleSwap}
                  style={{
                    padding: "10px",
                    backgroundColor: "#4caf50",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  disabled={loading}
                >
                  {loading ? "Swapping..." : "Swap"}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Swapcurrencies;
